import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  createSigneringApiDataFetcher,
  postWithoutResponse,
  useSigneringApi,
} from '../api/signeringApi';
import { ApiRequestStatus } from '../api/useApi';
import { API_ROOT } from '../api/apiUrls';
import resolvedConfig from '../config';
import { SecurityLevel } from '../idporten/securityLevel';
import { Session, sessionDecoder, SessionStatus, Target } from './session';
import useUserActivity from './useUserActivity';
import { trackError } from '../errorHandling/errorTracking';

let logoutSessionTimer: ReturnType<typeof setTimeout>;
let retrySessionTimer: ReturnType<typeof setTimeout>;

const initialSession: Session = { status: SessionStatus.INITIAL };

interface SessionContextInfo {
  session: Session;
  devModeLogin: (
    setPersonalIdentificationNumber: string,
    chosenSecurityLevel: SecurityLevel,
    target: Target
  ) => Promise<void>;
  refresh: () => Promise<void>;
}

const SessionContext = createContext<SessionContextInfo>({
  session: initialSession,
  devModeLogin: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
});

const fetchSession = createSigneringApiDataFetcher({
  name: 'Session',
  url: `${API_ROOT}/`,
  decoder: sessionDecoder,
});

const AppWithSession: FunctionComponent<
  PropsWithChildren & {
    logout: () => void;
    placeholder: React.ReactElement;
  }
> = ({ children, placeholder, logout: logoutCallback }) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);

  const logout = useCallback(() => {
    setLoggedOut(true);
    logoutCallback();
  }, [logoutCallback]);

  const { isActive } = useUserActivity(resolvedConfig.session.idleTimeout);

  const options = useMemo(
    () => ({
      enabled: isActive,
      refetchInterval: resolvedConfig.session.refreshInterval,
      initialData: initialSession,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      staleTime: 0,
    }),
    [isActive]
  );
  const { request, refetch, isRefetching } = useSigneringApi({
    dataFetcher: fetchSession,
    options,
  });

  const devModeLogin = useCallback(
    async (
      personalIdentificationNumber: string,
      chosenSecurityLevel: SecurityLevel,
      target: Target
    ) => {
      await postWithoutResponse(
        `${API_ROOT}/devmode-login/`,
        JSON.stringify({
          personalIdentificationNumber,
          chosenSecurityLevel,
          target,
        })
      ).then(() => {
        void refetch();
      });
    },
    [refetch]
  );

  useEffect(() => {
    if (
      !isActive &&
      request.status === ApiRequestStatus.SUCCESS &&
      request.result.status === SessionStatus.LOGGED_IN &&
      !isRefetching
    ) {
      void refetch();
      logoutSessionTimer = setTimeout(
        logout,
        resolvedConfig.session.sessionTimeout
      );
    } else if (isActive) {
      clearTimeout(logoutSessionTimer);
    }
    return () => clearTimeout(logoutSessionTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (request.status === ApiRequestStatus.FAILURE) {
      trackError(
        new Error(
          'Could not get session, because' +
            request.error.message +
            ' - ' +
            (request.error.errorResponse?.errorMessage || '')
        )
      );
    }
  }, [request]);

  useEffect(() => {
    if (request.status === ApiRequestStatus.FAILURE && failedAttempts < 5) {
      retrySessionTimer = setTimeout(() => void refetch(), 1000);
      setFailedAttempts(failedAttempts + 1);
    } else if (request.status === ApiRequestStatus.SUCCESS) {
      setFailedAttempts(0);
      clearTimeout(retrySessionTimer);
    }
    return () => clearTimeout(retrySessionTimer);
  }, [request, failedAttempts, refetch]);

  switch (request.status) {
    case ApiRequestStatus.IDLE:
    case ApiRequestStatus.PENDING: {
      return placeholder;
    }
    case ApiRequestStatus.FAILURE: {
      if (failedAttempts < 5) {
        return placeholder;
      }
      return (
        <SessionContext.Provider
          value={{
            session: { status: SessionStatus.UNKNOWN },
            devModeLogin,
            refresh: refetch,
          }}
        >
          {children}
        </SessionContext.Provider>
      );
    }
    case ApiRequestStatus.SUCCESS: {
      if (request.result.status === SessionStatus.INITIAL && !loggedOut) {
        return placeholder;
      }

      return (
        <SessionContext.Provider
          value={{ session: request.result, devModeLogin, refresh: refetch }}
        >
          {children}
        </SessionContext.Provider>
      );
    }
  }
};

export const useSession: () => SessionContextInfo = () => {
  const { session, devModeLogin, refresh } =
    useContext<SessionContextInfo>(SessionContext);

  return { session, devModeLogin, refresh };
};

export default AppWithSession;
