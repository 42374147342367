import { FunctionComponent, PropsWithChildren } from 'react';

import { Target } from '../session/session';
import { SecurityLevel } from './securityLevel';
import { useI18n } from '../language/i18n';
import { useTracking } from '../analytics/Analytics';
import { App } from '../../apps';

interface IDPortenLinkProps {
  target: Target;
  app: App;
  securityLevel: SecurityLevel;
  className?: string;
  focus?: boolean;
}

const IDPortenLink: FunctionComponent<
  IDPortenLinkProps & PropsWithChildren
> = ({
  children,
  target,
  app,
  securityLevel,
  className = 'hw-button hw-button--primary button-wide',
  focus = false,
}) => {
  const [translation, , , , language] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  return (
    <a
      href={`/web/idporten?target=${target}&lang=${language}&securityLevel=${securityLevel}`}
      className={className}
      onClick={() =>
        registerAnalyticsEvent({
          category: app,
          action: 'Klikket på logg inn med ID-porten',
        })
      }
      data-testid="id-porten-login-button"
      ref={(domElement) => {
        if (focus && domElement) domElement.focus();
      }}
    >
      {children !== undefined ? (
        <div>{children}</div>
      ) : (
        <>{translation.login.short}</>
      )}
    </a>
  );
};

export default IDPortenLink;
