import { FunctionComponent } from 'react';
import { useI18n } from '../language/i18n';

const RequiredStar: FunctionComponent<{ dataTestid?: string }> = ({
  dataTestid,
}) => {
  const [translation] = useI18n();
  return (
    <span data-testid={dataTestid}>
      <span className="screen-reader-only">{translation.uu.required}</span>
      <span aria-hidden={true} className="required-star">
        *
      </span>
    </span>
  );
};

export default RequiredStar;
