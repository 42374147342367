import { Fragment, ReactElement, PropsWithChildren } from 'react';

import { Params } from '@common/language/translate';
import RadioButton from './RadioButton';
import RadioButtonLabel, { RadioButtonLabelProps } from './RadioButtonLabel';

function RadioButtonGroup<ValueType extends string | number>({
  radioButtons,
  heading,
  chosenValue,
  onChange,
  name,
  children,
  app,
  description,
  containerClassName,
  headingClassName,
  disabled,
  dataTestid,
  ...infoBox
}: PropsWithChildren<RadioButtonGroupProps<ValueType>>): ReactElement {
  return (
    <Fragment>
      {(heading || description || infoBox) && (
        <RadioButtonLabel
          name={name}
          heading={heading}
          headingClassName={headingClassName}
          app={app}
          description={description}
          {...infoBox}
        />
      )}
      <div
        id={name}
        className={containerClassName}
        role="radiogroup"
        aria-labelledby={`radio-label-${name}`}
        data-testid={dataTestid}
      >
        {radioButtons.map((radioButton) => (
          <RadioButton
            key={radioButton.value}
            name={name}
            value={radioButton.value}
            label={radioButton.label}
            labelParams={radioButton.labelParams}
            onChange={onChange}
            chosenValue={chosenValue}
            disabled={disabled}
            dataTestid={`${name}-${radioButton.value}-radio`}
            ariaControls={radioButton.ariaControls}
          />
        ))}
        {children}
      </div>
    </Fragment>
  );
}

type RadioButtonsProps<ValueType extends string | number> = {
  value: ValueType;
  label: string;
  labelParams?: Params;
  ariaControls?: string;
};

type RadioButtonGroupProps<ValueType extends string | number> = {
  radioButtons: Array<RadioButtonsProps<ValueType>>;
  chosenValue: ValueType;
  name: string;
  onChange: (value: ValueType) => void;
  containerClassName?: string;
  disabled?: boolean;
  dataTestid?: string;
} & RadioButtonLabelProps;

export default RadioButtonGroup;
