import { PropsWithChildren, ReactElement } from 'react';
import { addClassesFromProps } from '@common/basicFunctions';
import { Params } from '@common/language/translate';
import './radio-button.less';
import { withParams } from '@common/language/i18n';

function RadioButton<ValueType extends string | number>({
  label,
  labelParams,
  value,
  onChange,
  chosenValue,
  wrapperClassName,
  name,
  disabled,
  dataTestid,
  ariaControls,
}: PropsWithChildren<RadioButtonProps<ValueType>>): ReactElement {
  return (
    <label
      className={`hw-radio-button radio-button hw-block--mb-smaller${addClassesFromProps(
        wrapperClassName
      )}`}
      aria-disabled={disabled}
    >
      <input
        type="radio"
        name={name}
        id={`${name}-${value}`}
        value={value}
        onChange={() => {
          if (!disabled) {
            onChange(value);
          }
        }}
        checked={!disabled && chosenValue === value}
        data-testid={dataTestid}
        aria-disabled={disabled}
        aria-controls={ariaControls}
      />
      {labelParams ? (
        <span
          className="hw-radio-button__label"
          dangerouslySetInnerHTML={{
            __html: withParams(label, labelParams),
          }}
        />
      ) : (
        <span
          className="hw-radio-button__label"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}

      <i
        className="hw-radio-button__indicator radio-button__indicator"
        aria-disabled={disabled}
      />
    </label>
  );
}

interface RadioButtonProps<ValueType extends string | number> {
  label: string;
  labelParams?: Params;
  name: string;
  value: ValueType;
  chosenValue: ValueType;
  wrapperClassName?: string;
  onChange: (value: ValueType) => void;
  disabled?: boolean;
  dataTestid?: string;
  ariaControls?: string;
}

export default RadioButton;
