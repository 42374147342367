import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '../basicFunctions';

const MobileOnlyHeaderInColumn: FunctionComponent<
  {
    extraClassName?: string;
  } & PropsWithChildren
> = ({ children, extraClassName }) => (
  <span
    aria-hidden={true}
    className={`ui-minmed column-header${addClassesFromProps(extraClassName)}`}
  >
    {children}
  </span>
);

export default MobileOnlyHeaderInColumn;
