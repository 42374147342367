import { FunctionComponent, PropsWithChildren } from 'react';

import { useI18n } from '../language/i18n';
import Heading from '../pageBuildingBlocks/Heading';

const SessionTimeout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [translation] = useI18n();

  return (
    <div
      id="session-timeout-message"
      className="content-wrapper content-wrapper-wide"
    >
      <div className="box invisible-div error-message-text">
        <Heading headerText={translation.common.session_timeout.heading} />
        <p className="error-message">
          {translation.common.session_timeout.message}
        </p>
        {children}
      </div>
    </div>
  );
};

export default SessionTimeout;
