import { FunctionComponent, useState } from 'react';
import { App } from '../../apps';
import InfoBoxButton from '@common/boxes/infobox/InfoBoxButton';
import InfoBoxWithVisibilityToggle from '@common/boxes/infobox/InfoBoxWithVisibilityToggle';

const RadioButtonLabel: FunctionComponent<RadioButtonLabelProps> = ({
  name,
  heading,
  headingClassName,
  app,
  description,
  infoBox,
}) => {
  const [infoboxVisible, setInfoboxVisible] = useState(false);

  return (
    <label
      className="form-section-label hw-block--mt-medium-2"
      htmlFor={name}
      id={`radio-label-${name}`}
    >
      <div>
        {heading && <span className={headingClassName}>{heading} </span>}
        {infoBox && (
          <InfoBoxButton
            visibility={infoboxVisible}
            onClick={() => {
              setInfoboxVisible(!infoboxVisible);
            }}
            app={app}
            name={name}
          />
        )}
      </div>
      {description && <span className="form-section-text">{description}</span>}
      {infoBox && infoBox.type === 'text' && (
        <InfoBoxWithVisibilityToggle visibility={infoboxVisible}>
          <p dangerouslySetInnerHTML={{ __html: infoBox.text }} />
        </InfoBoxWithVisibilityToggle>
      )}
      {infoBox && infoBox.type === 'component' && (
        <infoBox.Component visibility={infoboxVisible} />
      )}
    </label>
  );
};

interface WithInfoBoxText {
  text: string;
  type: 'text';
}

interface WithInfoBoxComponent {
  Component: FunctionComponent<{ visibility: boolean }>;
  type: 'component';
}

type InfoBox = WithInfoBoxText | WithInfoBoxComponent;

export type RadioButtonLabelProps = {
  infoBox?: InfoBox;
  name: string;
  heading?: string;
  app?: App;
  description?: string;
  headingClassName?: string;
};

export default RadioButtonLabel;
