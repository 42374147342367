import { DecoderFunction } from 'typescript-json-decoder';

export enum SecurityLevel {
  THREE = 3,
  FOUR = 4,
}
export const securityLevelDecoder: DecoderFunction<
  SecurityLevel | undefined
> = (value) => {
  if (value === 3) {
    return SecurityLevel.THREE;
  } else if (value === 4) {
    return SecurityLevel.FOUR;
  } else if (value === 0) {
    return undefined;
  } else {
    throw `${value as string}\` is not a valid SecurityLevel`;
  }
};

export const hasValidSecurityLevel = (
  jobSecurityLevel?: SecurityLevel,
  userSecurityLevel?: SecurityLevel
): boolean => {
  switch (jobSecurityLevel) {
    case SecurityLevel.FOUR:
      return userSecurityLevel === SecurityLevel.FOUR;
    case SecurityLevel.THREE:
      return (
        userSecurityLevel === SecurityLevel.THREE ||
        userSecurityLevel === SecurityLevel.FOUR
      );
    default:
      return true;
  }
};
