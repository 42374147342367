import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '@common/basicFunctions';

interface Props {
  extraClassName?: string;
}

const ErrorBlock: FunctionComponent<Props & PropsWithChildren> = ({
  children,
  extraClassName,
}) => {
  return (
    <section
      className={`hw-error-summary hw-block--px hw-block--mb${addClassesFromProps(
        extraClassName
      )}`}
    >
      {children}
    </section>
  );
};

export default ErrorBlock;
