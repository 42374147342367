import { DecoderFunction, record, union } from 'typescript-json-decoder';
import { maybeDecoder } from '../api/decoders';
import { securityLevelDecoder } from '../idporten/securityLevel';

export enum Target {
  SIGNER_TARGET = 'SIGNATURE_PORTAL',
  ORG_TARGET = 'ORGANIZATION_PORTAL',
  ADMINISTRATOR_PORTAL = 'ADMINISTRATOR_PORTAL',
}
const targetDecoder: DecoderFunction<Target> = (value) => {
  return union(...Object.values(Target))(value);
};

export enum SessionStatus {
  // Actual session status
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',

  // Extra session statuses for frontend
  INITIAL = 'INITIAL',
  UNKNOWN = 'UNKNOWN',
}
const sessionStatusDecoder: DecoderFunction<SessionStatus> = (value) => {
  return union(...Object.values(SessionStatus))(value);
};

export enum SessionActionType {
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
}

export enum SessionRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  DIGDIR_ADMINISTRATOR = 'DIFI_ADMINISTRATOR',
}
const sessionRoleDecoder: DecoderFunction<SessionRole> = (value) => {
  return union(...Object.values(SessionRole))(value);
};

export type Session = ReturnType<typeof sessionDecoder>;
export const sessionDecoder = record({
  status: sessionStatusDecoder,
  target: maybeDecoder(targetDecoder),
  securityLevel: securityLevelDecoder,
  role: maybeDecoder(sessionRoleDecoder),
});

export const isAdministrator = (session: Session) =>
  session.role === SessionRole.ADMINISTRATOR;
export const isDigdirAdministrator = (session: Session) =>
  session.role === SessionRole.DIGDIR_ADMINISTRATOR;

export function getSecurityLevel(session: Session) {
  if (!session.securityLevel) {
    throw new Error(`Excpected securityLevel on session, but found none.`);
  }
  return session.securityLevel;
}
