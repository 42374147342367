import { FunctionComponent, useState } from 'react';

import Input from '@hedwig/forms/Input';
import RadioButtonGroup from '@hedwig/forms/RadioButtonGroup';
import { PrimaryButton, SecondaryOutlinedButton } from '@hedwig/buttons';
import { SecurityLevel } from '../idporten/securityLevel';
import { Target } from '../session/session';
import Heading from '../pageBuildingBlocks/Heading';
import { useI18n, withParams } from '../language/i18n';

const DevModeLogInForm: FunctionComponent<DevModeLogInFormProps> = ({
  target,
  heading,
  login,
}) => {
  const [translation] = useI18n();
  const [personalIdentificationNumber, setPersonalIdentificationNumber] =
    useState('01047300564');
  const [chosenSecurityLevel, setChosenSecurityLevel] = useState(
    SecurityLevel.FOUR
  );
  const [shouldFail, setShouldFail] = useState(false);

  const handleLoginButton = () => {
    if (!personalIdentificationNumber) {
      return;
    }
    void login(personalIdentificationNumber, chosenSecurityLevel, target);
  };

  return (
    <div className="box">
      <Heading
        headerText={withParams(translation.devlogin.heading, { heading })}
        sizeClass="hw-h2"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLoginButton();
        }}
      >
        <Input
          name="pin"
          label={translation.portal.login.label}
          value={personalIdentificationNumber}
          onChange={(event) =>
            setPersonalIdentificationNumber(event.target.value)
          }
        />
        <RadioButtonGroup
          name="personalIdentificationNumber"
          heading={translation.devlogin.bruker}
          chosenValue={personalIdentificationNumber}
          onChange={setPersonalIdentificationNumber}
          radioButtons={[
            { value: '01047300564', label: 'Perline' },
            { value: '16079426869', label: 'Offentlig virksomhetsforvalter' },
            {
              value: '01048200229',
              label: 'Konrad (har ikke offentlig postkasse)',
            },
            {
              value: '09038390721',
              label: 'Preben (har offentlig postkasse), alle PAdES-maler',
            },
            {
              value: '41906801513',
              label: 'Demokratisk fiskebolle (syntetisk testbruker)',
            },
          ]}
        />
        <RadioButtonGroup
          name="securityLevel"
          heading={translation.devlogin.niva}
          chosenValue={chosenSecurityLevel}
          onChange={setChosenSecurityLevel}
          radioButtons={[
            {
              value: SecurityLevel.THREE,
              label: translation.orgportal.create.level3,
            },
            {
              value: SecurityLevel.FOUR,
              label: translation.orgportal.create.level4,
            },
          ]}
        />
        <PrimaryButton type="submit">
          {translation.devlogin.login}
        </PrimaryButton>
      </form>
      <div className="hw-block hw-block--mt-medium-3-responsive">
        <SecondaryOutlinedButton onClick={() => setShouldFail(true)}>
          {translation.devlogin.error_page}
        </SecondaryOutlinedButton>
        {shouldFail &&
          (() => {
            throw new Error('tester feilhåndtering');
          })()}
      </div>
    </div>
  );
};

interface DevModeLogInFormProps {
  login: (
    pid: string,
    chosenSecurityLevel: SecurityLevel,
    target: Target
  ) => Promise<void>;
  heading: string;
  target: Target;
}

export default DevModeLogInForm;
