import { FunctionComponent } from 'react';
import ErrorBlock from '@hedwig/errors/ErrorBlock';
import { useI18n } from '../language/i18n';
import { PrimaryButton } from '@hedwig/buttons';
import { useSession } from './AppWithSession';

const UnknownSessionStatus: FunctionComponent = () => {
  const [translation] = useI18n();
  const { refresh } = useSession();

  return (
    <ErrorBlock>
      <div className="hw-container">
        <p>{translation.common.session_unknown.message}</p>
        <PrimaryButton onClick={() => void refresh()}>
          {translation.common.session_unknown.retry}
        </PrimaryButton>
      </div>
    </ErrorBlock>
  );
};

export default UnknownSessionStatus;
