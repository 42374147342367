import { FunctionComponent } from 'react';

import RequiredStar from '@common/forms/RequiredStar';
import InputField, { BaseInputProps } from './InputField';
import { Validation, ValidationType } from '@common/validation/validation';
import InputErrorText from './InputErrorText';
import { addClassesFromProps } from '@common/basicFunctions';

import './input.less';
import '@common/forms/forms.less';
import { useI18n } from '@common/language/i18n';

type InputProps = {
  errorDataTestid?: string;
  wrapperClass?: string;
  showCounter?: boolean;
  label?: string;
  labelClass?: string;
  required?: boolean;
  helpText?: string;
  validation?: Validation;
} & BaseInputProps;

const Input: FunctionComponent<InputProps> = ({
  dataTestid,
  name,
  inputType = 'text',
  inputClass,
  value = '',
  placeholder = '',
  onKeyDown,
  label,
  labelClass,
  validation = { result: ValidationType.NOT_VALIDATED },
  wrapperClass,
  maxlength = 524288,
  onFocus,
  onBlur,
  showCounter = false,
  helpText,
  required = false,
  errorDataTestid,
  autocomplete,
  ...changeableInputProps
}) => {
  const [translation] = useI18n();
  const hasError = validation.result === ValidationType.INVALID_FIELD;
  return (
    <div className={wrapperClass}>
      <label
        htmlFor={name}
        className={`hw-label form-input__label${addClassesFromProps(
          labelClass
        )}${
          validation.result === ValidationType.INVALID_FIELD
            ? ' hw-label--error'
            : ''
        }`}
      >
        {required && (
          <RequiredStar
            dataTestid={dataTestid ? dataTestid + '-required-star' : undefined}
          />
        )}
        {label && <span>{label}</span>}
        {showCounter && (
          <span className="field-counter">
            {`${maxlength - value.length}`}{' '}
            {translation.orgportal.create.characters_counter_unit}
          </span>
        )}
      </label>
      <InputField
        inputType={inputType}
        dataTestid={dataTestid}
        name={name}
        value={value}
        maxlength={maxlength}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        inputClass={inputClass}
        autocomplete={autocomplete}
        hasError={hasError}
        {...changeableInputProps}
      />
      {!!helpText && !hasError && (
        <span className="form-input__help-text">{helpText}</span>
      )}

      {hasError && (
        <InputErrorText
          errorDataTestid={errorDataTestid}
          errorMessage={validation.errorMessage}
          ariaDescribedBy={name}
        />
      )}
    </div>
  );
};

export default Input;
