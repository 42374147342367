import {
  Fragment,
  FunctionComponent,
  ChangeEvent,
  FocusEvent,
  KeyboardEventHandler,
} from 'react';

import { addClassesFromProps } from '@common/basicFunctions';

interface InputFieldProps {
  hasError: boolean;
}

export type BaseInputProps = {
  inputClass?: string;
  dataTestid?: string;
  name: string;
  value?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  maxlength?: number;
  placeholder?: string;
  inputType?: string;
  autocomplete?: string;
} & WithReadOnly;

interface ReadOnlyInputField {
  readonly: true;
}

interface ChangeableInputField {
  readonly?: false;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

type WithReadOnly = ReadOnlyInputField | ChangeableInputField;

const InputField: FunctionComponent<InputFieldProps & BaseInputProps> = ({
  dataTestid = 'inputField-test',
  name,
  inputType = 'text',
  inputClass,
  value = '',
  placeholder = '',
  onKeyDown,
  maxlength = 524288,
  onFocus,
  onBlur,
  autocomplete,
  hasError,
  ...changableInputProps
}) => {
  return (
    <Fragment>
      <input
        className={`hw-input${addClassesFromProps(inputClass)}${
          hasError ? ' hw-input--error' : ''
        }${changableInputProps.readonly ? ' input-disabled' : ''}`}
        type={inputType}
        data-testid={dataTestid}
        name={name}
        id={name}
        value={value}
        maxLength={maxlength}
        readOnly={changableInputProps.readonly}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={
          !changableInputProps.readonly
            ? changableInputProps.onChange
            : undefined
        }
        onKeyDown={onKeyDown}
        autoComplete={autocomplete}
        aria-invalid={hasError}
      />
    </Fragment>
  );
};

export default InputField;
